.job-detail-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 3rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  background-color: rgb(0 0 0);

}

.bordered-field {
  border: 2px solid #00e6b6; /* Set the border color */
  border-radius: 8px; /* Set the border radius */
  padding: 0.5rem; /* Add padding to create space between border and content */
  margin-bottom: 1rem; /* Add margin to separate fields */
}

.job-detail {
  justify-content: center;
  align-self: flex-start;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: 0.25rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  color: rgb(74 222 128);
  background-color: rgb(0 0 0);
  border-width: 1px;
  border-color: rgb(0 0 0);
  border-style: solid;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  backdrop-filter: blur(2px);
}

@media not all and (min-width: 768px) {
  .job-detail {
    max-width: 100%;
  }
}

.job-detail-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  margin-top: 0.75rem;
  margin-left: 0px;
  width: 95%;
}

@media not all and (min-width: 768px) {
  .job-detail-section {
    max-width: 100%;
  }
}

.job-detail-item {
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 1.75rem;
  background-color: rgb(0 0 0);
  border-width: 1px;
  border-color: rgb(0 0 0);
  border-style: solid;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  backdrop-filter: blur(2px);
  color: rgb(255 255 255 / 0.5);
  width: calc(50% - 0.5rem); /* Occupy half of the width */
  margin-right: 1rem; /* Add space between items */
}

.job-detail-label {
  color: rgb(255 255 255);
}

.job-description {
  justify-content: center;
  align-self: stretch;
  padding-top: 0.25rem;
  padding-bottom: 0.875rem;
  margin-top: 1.75rem;
  background-color: rgb(0 0 0);
  border-width: 1px;
  border-color: rgb(0 0 0);
  border-style: solid;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  backdrop-filter: blur(2px);
  width: 100%; /* Occupy full width */
 
}

@media not all and (min-width: 768px) {
  .job-description {
    padding-right: 1.25rem;
    max-width: 100%;
  }
}

.job-description-label {
  color: rgb(255 255 255);
}

.job-description-text {
  color: rgb(163 163 163);
}

.job-detail-link {
  justify-content: center;
  align-self: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 9rem;
  margin-left: 0px;
  text-decoration-line: underline;
  background-color: rgb(0 0 0);
  border-width: 1px;
  border-color: rgb(0 0 0);
  border-style: solid;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  backdrop-filter: blur(2px);
  width: calc(50% - 0.5rem); /* Occupy half of the width */
  margin-right: 1rem; /* Add space between items */
}



@media not all  {
  .job-detail-link {
    /* margin-top: 2.5rem; */
    max-width: 100%;
  }
}

.job-detail-link-label {
  color: rgb(255 255 255);
}

.job-detail-link-url {
  justify-content: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  /* margin-top: 1.75rem; */
  background-color: rgb(0 0 0);
  border-width: 1px;
  border-color: rgb(0 0 0);
  border-style: solid;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  backdrop-filter: blur(2px);
  color: rgb(255 255 255 / 0.5);
  width: calc(50% - 0.5rem); /* Occupy half of the width */
  margin-right: 1rem; /* Add space between items */
  text-decoration-line: underline;
}


.relevance-score-button {
  padding: 0.5rem 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid transparent;
  font-size: 17px;
  background-color: inherit;
  border-radius: 100px;
  margin-left: 140px;
  font-weight: 600;
  color: #00e6b6;
  box-shadow: 0 0 0 2px #00e6b6;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.relevance-score-button:hover {
  box-shadow: 10 0 0 12px transparent;
  color: greenyellow;
  border-radius: 12px; 
}

.piechart-container {
  background-color: rgb(21, 19, 19);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 400px;
}

.pie-chart-display {
  display: flex;
  align-items: center;
}

.pie-chart {
  background: conic-gradient(
    rgb(21, 19, 19) ,  /* Black slice */
    rgb(21, 19, 19) , /* Black slice */
    rgb(27, 203, 212) , /* Blue slice */
    rgb(27, 203, 212)  /* Blue slice */
  );
  border-radius: 50%;
  width: 250px;
  height: 250px;
}

.score-display{
  margin-left: 5px;
  padding: 2px;
}
.white {
  color: white;
}

.location{
  border: 2px solid #00e6b6; /* Set the border color */
  border-radius: 8px; /* Set the border radius */
  padding: 0.5rem; /* Add padding to create space between border and content */
  margin-bottom: 1rem; /* Add margin to separate fields */
}

.job-links-container {
  display: flex;
  flex-direction: column; /* Arrange links vertically */
  background-color:#00e6b6;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0px 40px 0px 40px;
  border-radius: 10px; 
  padding: 20px;;
}

.job-link-reed{
  background-color: red;
}
