@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  background-color: #000000;
  font-family: 'Roboto', sans-serif;
  background-image: url('./assets/SHPBackground_2.png'); 
  background-size: cover; 
  background-position: center;
  height: 100%;
}
.App {
  text-align: center;
}
.App-logo {
  height: 300px;
  pointer-events: none;
  margin: 0;
  box-shadow: 10px 5px 5px rgb(0, 0, 0);
  border-radius: 25px;
  border: 1px solid #000000	;
  padding: 10px;
  background-color: rgb(0, 0, 0);
  
}
.App-header {
  
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + vmin);
  color: #00e6b6;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;;
  
}
.Upload-section h2 {
  margin-bottom: 10px;
}
.Upload-section {
 margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  
}
.Upload-section input {
  appearance: none;
  background-color: #03ffab44;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
  min-height: 90px;
  min-width: 90px;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  will-change: transform;
}
.Upload-section input:focus {
  border-color: #03ffab;
  outline: none;
  box-shadow: 0 0 10px rgba(3, 255, 171, 0.5);
}
.Upload-section button {
  background-color: #0f9ec6;
  padding: 10px;
  padding-bottom: 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}
.Upload-section button {
  appearance: none;
  background-color: #03ffab;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 60%;
  will-change: transform;
  text-transform: uppercase;
}
.Upload-section button:disabled {
  pointer-events: none;
}
.Upload-section button:hover {
  box-shadow: rgb(0, 0, 0) 0 8px 15px;
  transform: translateY(-2px);
}
.Upload-section button:active {
  box-shadow: none;
  transform: translateY(0);
}

.container{
  align-items:center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin:auto;
  margin-top: 100px;
  margin-bottom:20px;
  width: 90%;
  max-width: 600px;
  
  padding:20px;
  background-color: #07000092;
  border-radius: 25px;
  border: 1px solid #595757	;
  margin-right: 290px;;
}

.header{
  display:flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  width:100%;
  margin-top: 0px;
}

.text{
  color:green;
  font-size: 48px;
  font-weight: 700; 
}

.underline{
  width: 61px;
  height: 6px;
  background:gold;
  border-radius:9px;
}

.inputs {
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;
  gap: 25px;
  width: 100%;
}


.input {
  display:flex;
  align-items:center;
  justify-content: center;
  margin:10px;
  margin-top: 15px;
  width: 100%;
  height: 90px; 
  background: #001914;
  border-radius: 5%; 
  padding: 10px; 
}


.inputBox {
  display:flex;
  align-items:center;
  justify-content: center;
  margin:5px;
  width: 40%;
  height: 90px;
  border-radius:10px;
}

.input img{
  margin:0px 30px;
}

.input input {
  height: 50%;
  width: 400%;
  background: transparnet;
  border: none;
  outline: none;
  color:#780000;
  font-size: 19px; 
  padding:2px;
}

.forgot-password{
  padding-left: 62px;
  margin-top: 27px;
  color: black;
  font-size: 18px;
}

.forgot-password span{
  color: purple;
  cursor: pointer;
}

.submit-container{
  display:flex;
  gap:30px;
  margin:60px auto;
  justify-content: center;
  align-items: center;
}

.submit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 16px 36px;
  border: 4px solid transparent;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: #00e6b6 ;
  box-shadow: 0 0 0 2px  #00e6b6 ;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

.submit:hover {
  box-shadow: 10 0 0 12px transparent;
  color: greenyellow;
  border-radius: 12px;
}

.submit:active {
  scale: 0.95;
  box-shadow: 0 0 0 4px greenyellow;
}

.submit .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  background-color: greenyellow;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.submit:hover .circle {
  width: 220px;
  height: 220px;
  opacity: 1;

}

.submit svg {
  position: absolute;
  width: 24px;
  fill: greenyellow;
  z-index: 9;
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.submit .arr-1 {
  right: 16px;
}

.submit .arr-2 {
  left: -25%;
}

.submit .text {
  position: relative;
  z-index: 1;
  transform: translateX(-12px);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
}

.submit:hover .arr-1 {
  right: -25%;
}

.submit:hover .arr-2 {
  left: 16px;
}

.submit:hover .text {
  transform: translateX(12px);
}

.submit:hover svg {
  fill: #00e6b6 ;
}


.gray{
  background:#EAEAEA;
  color:#676767;
}